import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { ChatContainer } from "./Message"

const MobileChat = () => {
  const { userId } = useParams();

  useEffect(() => {
    const fetchCookie = async () => {
      try {
        // Get the cookie value directly using js-cookie
        const cookieValue = Cookies.get("hai_auth", { domain: ".helpai.co" });

        // console.log("Cookie Value:", cookieValue);

        if (cookieValue) {
          // Parse the JSON string to get individual values
          const cookieObject = JSON.parse(cookieValue);
          const { email, idToken: accessToken } = cookieObject;

          // console.log("Email:", email);
          // console.log("Access Token:", accessToken);

          // Set cookies using js-cookie
          Cookies.set("userEmail", email);
          Cookies.set("accessToken", accessToken);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("accessToken", accessToken);
        }
      } catch (error) {
        console.error("Error parsing cookie value:", error);
      }
    };

    fetchCookie();
  }, []);

  return (
    <div>
      <ChatContainer currentChatUser={{ _id: userId }} />
    </div>
  )
}

export default MobileChat;