import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Cookies from 'js-cookie';

import { Label } from './Questions';
import uploadButton from '../../assets/images/new-upload.svg';
import mobilelogo from '../../assets/images/logomobile.svg';
import BASE_URL from '../../services';
import { industryOptions } from '../../assets/staticdata/industry';
import { skillOptions } from '../../assets/staticdata/skillsOption';
import { locationData } from '../../assets/staticdata/location';

const endpoint = '/auth/additional-information';

const QuestionsNo = () => {
	const haveStartup = 'No';
	const email = localStorage.getItem('userEmail');
	const [portfolio, setPortfolio] = useState('');
	const [industry, setIndustry] = useState('');
	const [resume, setResume] = useState('');
	const [elevatorPitch, setElevatorPitch] = useState('');
	const [skillDemanded, setSkillDemanded] = useState([]);
	const [userLocation, setUserLocation] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [wordCount, setWordCount] = useState(0);
	const [showindustryOptions, setshowindustryOptions] = useState(false);
	const [showSkillsOptions, setShowSkillsOptions] = useState(false);
	const [locationSuggestions, setLocationSuggestions] = useState([]);
	const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);

	useEffect(() => {
		const cookieValue = Cookies.get('hai_auth', { domain: '.helpai.co' })

		// console.log('Cookie Value:', cookieValue);

		if (cookieValue) {
			const decodedCookie = decodeURIComponent(cookieValue);

			// console.log('Decoded Cookie:', decodedCookie);

			const cookieObject = JSON.parse(decodedCookie);
			const { email, idToken: accessToken } = cookieObject;

			// console.log('Email:', email);
			// console.log('Access Token:', accessToken);

			Cookies.set('userEmail', email);
			Cookies.set('accessToken', accessToken);
			localStorage.setItem('userEmail', cookieObject.email);
		}
	}, []);

	const filterLocationSuggestions = (query) => {
		const filteredSuggestions = locationData.filter((location) =>
			`${location.city}, ${location.country}`.toLowerCase().includes(query.toLowerCase())
		);
		setLocationSuggestions(filteredSuggestions);
	};

	// Handle location input change and filter suggestions
	const handleLocationInputChange = (event) => {
		const { value } = event.target;
		setUserLocation(value);
		filterLocationSuggestions(value);
	};

	const handleSelectLocation = (location) => {
		setUserLocation(location);
		setShowLocationSuggestions(false);
	};

	useEffect(() => {
		if (locationSuggestions.length > 0) {
			setShowLocationSuggestions(true);
		} else {
			setShowLocationSuggestions(false);
		}
	}, [locationSuggestions]);

	const toggleIndustryOptions = () => {
		setshowindustryOptions(!showindustryOptions);
	};

	const handleSelectIndustry = (stage) => {
		setIndustry(stage);
		setshowindustryOptions(false);
	};

	const toggleSkillsOptions = () => {
		setShowSkillsOptions(!showSkillsOptions);
	};

	const handleSelectSkill = (skill) => {
		if (skillDemanded.includes(skill)) {
			setSkillDemanded(skillDemanded.filter((selectedSkill) => selectedSkill !== skill));
		} else if (skillDemanded.length < 3) {
			setSkillDemanded([...skillDemanded, skill]);
		}
		if (skillDemanded.length === 3) {
			setShowSkillsOptions(false);
		}
	};

	const updateWordCount = (value) => {
		const words = value.trim().split(/\s+/);
		setWordCount(words.length);
	};

	const setElevatorPitchValue = (value) => {
		if (value.length <= 400) {
			setElevatorPitch(value);
			updateWordCount(value);
		}
	};

	const handleSkillsInputChange = (event) => {
		const { value } = event.target;
		setSkillDemanded([value]);
	};

	// const handleFileChange = (event) => {
	// 	const file = event.target.files[0];
	// 	setSelectedFile(file);
	// 	console.log(selectedFile, file);
	// 	setResume(file); // Set resume as the selected file name
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSubmitting(true);
		const data = new FormData();
		data.append('email', email);
		data.append('haveStartup', haveStartup);
		data.append('elevatorPitch', elevatorPitch);
		data.append('industry', industry);
		skillDemanded.forEach((skill, index) => {
			data.append(`skillDemanded[${index}]`, skill);
		});
		data.append('userLocation', userLocation);
		data.append('portfolio', portfolio);
		if (selectedFile) {
			data.append('resume', selectedFile, selectedFile.name);
		}

		axios
			.post(BASE_URL + endpoint, data)
			.then((response) => {
				setElevatorPitch('');
				setIndustry('');
				setSkillDemanded([]);
				setUserLocation('');
				setPortfolio('');
				setSelectedFile(null);
				setResponse(response.data.Message);
				setIsSubmitting(false);
				setTimeout(() => {
					navigate('/upload-profile-picture', { replace: true });
				}, 9000);
			})
			.catch((error) => {
				console.error(error);
				setIsSubmitting(false);
				setError("Email not verified, Kindly verify your email");
				setIsSubmitting(false);
				setTimeout(() => {
					setError('');
				}, 5000);
			});
	};

	const isFormValid = elevatorPitch && industry && skillDemanded && portfolio;

	return (
		<>
			{isTabletOrMobile ? (
				<>
					<form
						className='mb-6'
						onSubmit={handleSubmit}>
						<Label
							html='Elevator pitch'
							title='Elevator pitch'
						/>
						<textarea
							required
							type='text'
							value={elevatorPitch}
							onChange={(e) => setElevatorPitchValue(e.target.value)}
							placeholder='Talk about yourself'
							className='bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3'
						/>
						<p className='mb-5'>{`${elevatorPitch.length}/400 characters`}</p>
						<Label
							html='What industry are you interested in?'
							title='What industry are you interested in?'
						/>
						<div className='flex flex-col'>
							<button
								type='button'
								onClick={toggleIndustryOptions}
								className={clsx([
									isTabletOrMobile ? 'w-full' : 'w-[417px]',
									'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
								])}>
								{industry || 'Select Industry'}
								<span className='ml-2'>
									{/* Custom arrow icon */}
									<svg
										className={clsx(['w-5 h-5', showindustryOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M19 9l-7 7-7-7'
										/>
									</svg>
								</span>
							</button>
							{/*  */}
							{showindustryOptions && (
								<div
									className={clsx([
										'absolute z-10 top-[530px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg',
									])}>
									{industryOptions.map((stage) => (
										<button
											key={stage}
											type='button'
											onClick={() => handleSelectIndustry(stage)}
											className={clsx(['w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md'])}>
											{stage}
										</button>
									))}
								</div>
							)}
						</div>
						<Label
							html='What skills do you have?'
							title='What skills do you have?'
						/>
						<button
							type='button'
							onClick={toggleSkillsOptions}
							className={clsx([
								isTabletOrMobile ? 'w-full' : 'w-[417px]',
								'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
							])}>
							{skillDemanded.length === 0
								? 'Select 3 Desired skills'
								: skillDemanded.slice(0, 3).map((selectedSkill, index) => (
										<span
											key={index}
											className='bg-purple-500 text-white rounded-full px-2 py-1 mr-2 flex items-center'>
											{selectedSkill}
											<button
												type='button'
												className='ml-2 text-[#AFAFAF] hover:text-[#414080]'
												onClick={() => handleSelectSkill(selectedSkill)}>
												X
											</button>
										</span>
								  ))}
							{skillDemanded.length > 3 && <span className='ml-2'>+{skillDemanded.length - 3}</span>}
							<span className='ml-2'>
								{/* Custom arrow icon */}
								<svg
									className={clsx(['w-5 h-5', showSkillsOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 9l-7 7-7-7'
									/>
								</svg>
							</span>
						</button>

						{/*  */}
						{showSkillsOptions && (
							<div className='absolute top-[630px] z-10 w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[140px] overflow-y-auto'>
								{skillOptions.map((skill) => (
									<button
										key={skill}
										type='button'
										onClick={() => handleSelectSkill(skill)}
										className={clsx([
											'w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md',
											skillDemanded.includes(skill) && 'bg-[#F5F5FF] text-[#414080] font-semibold', // Highlight selected skills
										])}>
										{skill}
									</button>
								))}
							</div>
						)}
						<Label
							html='Location'
							title='Location'
						/>
						<input
							required
							type='text'
							value={userLocation}
							onChange={handleLocationInputChange}
							placeholder='Search your location'
							className='bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5'
						/>
						{showLocationSuggestions && (
							<div className='absolute z-10 top-[729px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg'>
								<ul className='suggestion-list'>
									{locationSuggestions.map((suggestion, index) => (
										<li
											className='w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md cursor-pointer'
											key={index}
											onClick={() => handleSelectLocation(`${suggestion.city}, ${suggestion.country}`)}>
											{suggestion.city}, {suggestion.country}
										</li>
									))}
								</ul>
							</div>
						)}
						<Label
							html='Link to your portfolio'
							title='Link to your portfolio'
						/>
						<input
							required
							type='text'
							value={portfolio}
							onChange={(e) => setPortfolio(e.target.value)}
							placeholder='https://your-portfolio-link.com'
							className='bg-white rounded-lg border border-1 border-[#CFCFCF] outline-none w-full px-6 py-3 mb-5'
						/>
						<div className='flex flex-row items-center justify-between'>
							<div className=''>
								<div>
									<Label
										html='Upload your resume'
										title='Upload your resume'
									/>
									<input
										type='file'
										name='resume'
										accept='.pdf, .docx, .doc'
										onChange={(e) => setSelectedFile(e.target.files[0])}
										className='hidden'
									/>
								</div>
								<div className='mt-2'>
									<div>
										<label
											htmlFor='upload'
											className='flex items-center text-black bg-transparent border border-[#454488] rounded-md cursor-pointer px-5 py-3 gap-x-2'>
											<img
												src={uploadButton}
												alt='Upload'
												className=''
											/>
											<p className='text-[#454488]'>Upload file</p>
										</label>
										<input
											id='upload'
											type='file'
											name='resume'
											accept='.pdf, .docx, .doc'
											onChange={(e) => setSelectedFile(e.target.files[0])}
											className='hidden'
										/>
									</div>
								</div>
							</div>
						</div>
						{selectedFile && <p className='mt-2 text-gray-600'>Selected file: {selectedFile.name}</p>}
						<button
							className={clsx([
								'w-full h-[49px] bg-[#414080] text-[#FFFFFF] rounded-[8px] mt-4',
								{
									'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
								},
							])}
							disabled={!isFormValid || isSubmitting}>
							{isSubmitting ? 'Submitting...' : 'Continue'}
						</button>
					</form>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-normal px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-normal px-[21px] py-4 absolute top-7 right-7 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</>
			) : (
				<>
					<form
						onSubmit={handleSubmit}
						className=' flex flex-col mb-[25px]'>
						<Label
							html='Elevator Pitch'
							title='Elevator pitch (sell yourself and skills)'
						/>
						<textarea
							type='text'
							id='Elevator Pitch'
							value={elevatorPitch}
							onChange={(e) => setElevatorPitchValue(e.target.value)}
							placeholder='Talk about yourself'
							className={clsx([
								'w-[417px] h-[153px] rounded-[8px] inputbox p-3 mt-[8px] border-[1.03915px] border-[#AFAFAF]',
							])}
						/>
						<p className='mb-5'>{`${elevatorPitch.length}/400 characters`}</p>
						{/*  */}
						<div className='flex flex-col'>
							<Label
								html='Industry'
								title='What industry are you interested in?'
							/>
							<button
								type='button'
								onClick={toggleIndustryOptions}
								className={clsx([
									isTabletOrMobile ? 'w-full' : 'w-[417px]',
									'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
								])}>
								{industry || 'Select Industry'}
								<span className='ml-2'>
									{/* Custom arrow icon */}
									<svg
										className={clsx(['w-5 h-5', showindustryOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M19 9l-7 7-7-7'
										/>
									</svg>
								</span>
							</button>
							{/*  */}
							{showindustryOptions && (
								<div
									className={clsx([
										'absolute z-10 top-[519px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg',
									])}>
									{industryOptions.map((stage) => (
										<button
											key={stage}
											type='button'
											onClick={() => handleSelectIndustry(stage)}
											className={clsx(['w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md'])}>
											{stage}
										</button>
									))}
								</div>
							)}
						</div>
						{/*  */}
						<div className='flex flex-col'>
							<Label
								html='Skills'
								title='What skills do you have?'
							/>
							<button
								type='button'
								onClick={toggleSkillsOptions}
								className={clsx([
									isTabletOrMobile ? 'w-full' : 'w-[417px]',
									'h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF] appearance-none flex items-center justify-between focus:border-[#414080] focus:outline-none',
								])}>
								{skillDemanded.length === 0
									? 'Select 3 Desired skills'
									: skillDemanded.slice(0, 3).map((selectedSkill, index) => (
											<span
												key={index}
												className='bg-purple-500 text-white rounded-full px-2 py-1 mr-2 flex items-center'>
												{selectedSkill}
												<button
													type='button'
													className='ml-2 text-[#AFAFAF] hover:text-[#414080]'
													onClick={() => handleSelectSkill(selectedSkill)}>
													X
												</button>
											</span>
									  ))}
								{skillDemanded.length > 3 && <span className='ml-2'>+{skillDemanded.length - 3}</span>}
								<span className='ml-2'>
									{/* Custom arrow icon */}
									<svg
										className={clsx(['w-5 h-5', showSkillsOptions && 'transform rotate-180', 'text-[#AFAFAF]'])}
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M19 9l-7 7-7-7'
										/>
									</svg>
								</span>
							</button>

							{/*  */}
							{showSkillsOptions && (
								<div className='absolute top-[621px] z-10 w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[311px] overflow-y-auto'>
									{skillOptions.map((skill) => (
										<button
											key={skill}
											type='button'
											onClick={() => handleSelectSkill(skill)}
											className={clsx([
												'w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md',
												skillDemanded.includes(skill) && 'bg-[#F5F5FF] text-[#414080] font-semibold', // Highlight selected skills
											])}>
											{skill}
										</button>
									))}
								</div>
							)}
						</div>
						{/*  */}
						{/*  */}
						<div className='flex flex-col'>
							<Label
								html='Location'
								title='Location'
							/>
							<input
								type='text'
								id='Location'
								value={userLocation}
								onChange={handleLocationInputChange}
								placeholder='Search your location'
								className={clsx([
									'relative w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF]',
								])}
							/>
							{showLocationSuggestions && (
								<div className='absolute z-10 top-[725px] w-[417px] mt-1 py-1 px-4 bg-white border border-[#E6E6E6] rounded-lg h-[200px] overflow-y-auto'>
									<ul className=''>
										{locationSuggestions.map((suggestion, index) => (
											<li
												className='w-full p-3 text-left hover:bg-[#F5F5FF] rounded-md cursor-pointer'
												key={index}
												onClick={() => handleSelectLocation(`${suggestion.city}, ${suggestion.country}`)}>
												{suggestion.city}, {suggestion.country}
											</li>
										))}
									</ul>
								</div>
							)}
						</div>
						{/*  */}
						<Label
							html='Portfolio'
							title='Link to your portfolio'
						/>
						<input
							type='text'
							id='Portfolio'
							value={portfolio}
							onChange={(e) => setPortfolio(e.target.value)}
							placeholder='https://your-portfolio-link.com'
							className={clsx([
								'w-[417px] h-[52px] rounded-[8px] inputbox p-3 mt-[8px] mb-[18px] border-[1.03915px] border-[#AFAFAF]',
							])}
						/>
						{/*  */}
						<div className='flex gap-3 items-center'>
							<div className=''>
								<div>
									<Label
										html='Upload your resume'
										title='Upload your resume'
									/>
									<input
										type='file'
										name='resume'
										accept='.pdf, .docx, .doc'
										onChange={(e) => setSelectedFile(e.target.files[0])}
										className='hidden'
									/>
								</div>
								<div className='mt-2'>
									<div>
										<label
											htmlFor='upload'
											className='flex items-center text-black bg-transparent border border-[#454488] rounded-md cursor-pointer px-5 py-3 gap-x-2'>
											<img
												src={uploadButton}
												alt='Upload'
												className=''
											/>
											<p className='text-[#454488]'>Upload file</p>
										</label>
										<input
											id='upload'
											type='file'
											name='resume'
											accept='.pdf, .docx, .doc'
											onChange={(e) => setSelectedFile(e.target.files[0])}
											className='hidden'
										/>
									</div>
								</div>
							</div>
							<div className='mt-5 w-[250px] p-2'>
								{selectedFile && <p className='text-gray-600'>Selected file: {selectedFile.name}</p>}
							</div>
						</div>
						{/* button */}
						<button
							className={clsx([
								'w-[419px] h-[49px] bg-[#414080] text-white font-semibold rounded-lg mt-14',
								{
									'cursor-not-allowed opacity-50': !isFormValid || isSubmitting,
								},
							])}
							disabled={!isFormValid || isSubmitting}>
							{isSubmitting ? 'Submitting...' : 'Submit'}
						</button>
					</form>
					<AnimatePresence>
						{response && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{response}</p>
							</motion.div>
						)}
					</AnimatePresence>
					{/*  */}
					<AnimatePresence>
						{error && (
							<motion.div
								initial={{ opacity: 0, x: '100%' }}
								animate={{ opacity: 1, x: 0 }}
								exit={{ opacity: 0, x: '100%' }}
								transition={{ duration: 0.3 }}
								className='text-white text-xl px-[21px] py-4 absolute top-1 right-1 bg-[#5655AA] flex justify-center items-center rounded-lg'>
								<p>{error}</p>
							</motion.div>
						)}
					</AnimatePresence>
				</>
			)}
		</>
	);
};

export default QuestionsNo;
