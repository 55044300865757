import React, { useState, useEffect } from 'react';
import axios from 'axios';

import BASE_URL from './index.js';
import useProfile from '../hooks/useProfile.js';
import useUser from '../hooks/useUser.jsx';

const fetchProfileData = async (url, authToken) => {
	const headers = {
		Authorization: `Bearer ${authToken}`,
	};

	try {
		const response = await axios.get(url, { headers });
		// console.log(response.data);
		// console.log(response.data.isQuestionAnswered);
		return response.data;
	} catch (error) {
		throw new Error('Failed to fetch profile data');
	} 
};

export const useProfileData = () => {
	const { profile, setProfile } = useProfile();
	// console.log(`profile context` + profile);
	const getAuthTokenFromLocalDb = () => {
		return localStorage.getItem('responseData');
	};

	const authTokenWithQuotes = getAuthTokenFromLocalDb();
	const authToken = authTokenWithQuotes.replace(/^"(.*)"$/, '$1');
	const url = BASE_URL + `/account/settings/profile`;

	const [profileData, setProfileData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [tier, setTier] = useState();
	const { user, setUser } = useUser();

	useEffect(() => {
		if (!authToken) {
			setIsError(true);
			setIsLoading(false);
			return;
		}

		fetchProfileData(url, authToken)
			.then((data) => {
				// console.log(data);
				const fetchedTier = data?.data?.tier;
				setTier(fetchedTier);
				setUser((prev) => ({ ...prev, tier: fetchedTier }));
				setProfileData(data);
				setProfile(data);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsError(true);
				setIsLoading(false);
			});
	}, [url, authToken]);

	return {
		profileData,
		isLoading,
		isError,
	};
};

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import BASE_URL from "./index.js";
// import useProfile from "../hooks/useProfile.js";
// import useUser from "../hooks/useUser.jsx";

// const fetchProfileData = async (url, authToken) => {
//   const headers = {
//     Authorization: `Bearer ${authToken}`,
//   };
//   try {
//     const response = await axios.get(url, { headers });
//     return response.data;
//   } catch (error) {
//     throw new Error("Failed to fetch profile data");
//   }
// };

// const getAuthTokenFromLocalStorage = () => {
//   const localStorageToken = localStorage.getItem("responseData");
//   if (localStorageToken) {
//     console.log("Token found in localStorage:", localStorageToken);
//     return localStorageToken.replace(/^"(.*)"$/, "$1");
//   }
//   return null;
// };

// export const useProfileData = () => {
//   const { profile, setProfile } = useProfile();
//   const { user, setUser } = useUser();
//   const [profileData, setProfileData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isError, setIsError] = useState(false);
//   const [tier, setTier] = useState();
//   const [authToken, setAuthToken] = useState(null);

//   const url = BASE_URL + `/account/settings/profile`;

//   useEffect(() => {
//     const getAuthTokenFromCookie = () => {
//       const cookieToken = Cookies.get("accessToken", { domain: ".helpai.co" });
//       if (cookieToken) {
//         console.log("Token found in cookie:", cookieToken);
//         return cookieToken.replace(/^"(.*)"$/, "$1");
//       }
//       return null;
//     };

//     const token = getAuthTokenFromCookie() || getAuthTokenFromLocalStorage();
//     setAuthToken(token);
//   }, []);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       if (!authToken) {
//         setIsError(true);
//         setIsLoading(false);
//         return;
//       }

//       try {
//         const data = await fetchProfileData(url, authToken);
//         console.log("Profile data:", data);
//         const fetchedTier = data?.data?.tier;
//         setTier(fetchedTier);
//         setUser((prev) => ({ ...prev, tier: fetchedTier }));
//         setProfileData(data);
//         setProfile(data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching profile:", error);
//         setIsError(true);
//         setIsLoading(false);
//       }
//     };

//     fetchProfile();
//   }, [url, authToken, setUser, setProfile]);

//   return {
//     profileData,
//     isLoading,
//     isError,
//     tier,
//   };
// };

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";
// import BASE_URL from "./index.js";
// import useProfile from "../hooks/useProfile.js";
// import useUser from "../hooks/useUser.jsx";

// const fetchProfileData = async (url, authToken) => {
//   const headers = {
//     Authorization: `Bearer ${authToken}`,
//   };
//   try {
//     const response = await axios.get(url, { headers });
//     return response.data;
//   } catch (error) {
//     throw new Error("Failed to fetch profile data");
//   }
// };

// const getAuthTokenFromCookie = () => {
//   // Try to get the token from the accessToken cookie first
//   const cookieToken = Cookies.get("accessToken", { domain: ".helpai.co" });
//   if (cookieToken) {
//     console.log("Token found in cookie:", cookieToken);
//     return cookieToken.replace(/^"(.*)"$/, "$1");
//   }
//   return null;
// };

// const getAuthTokenFromLocalStorage = () => {
//   const localStorageToken = localStorage.getItem("responseData");
//   if (localStorageToken) {
//     console.log("Token found in localStorage:", localStorageToken);
//     return localStorageToken.replace(/^"(.*)"$/, "$1");
//   }
//   return null;
// };

// const getAuthToken = () => {
//   return getAuthTokenFromCookie() || getAuthTokenFromLocalStorage();
// };

// export const useProfileData = () => {
//   const { profile, setProfile } = useProfile();
//   const { user, setUser } = useUser();
//   const [profileData, setProfileData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isError, setIsError] = useState(false);
//   const [tier, setTier] = useState();

//   const url = BASE_URL + `/account/settings/profile`;

//   useEffect(() => {
//     const fetchProfile = async () => {
//       const authToken = getAuthToken();
//       if (!authToken) {
//         setIsError(true);
//         setIsLoading(false);
//         return;
//       }

//       try {
//         const data = await fetchProfileData(url, authToken);
//         console.log("Profile data:", data);
//         const fetchedTier = data?.data?.tier;
//         setTier(fetchedTier);
//         setUser((prev) => ({ ...prev, tier: fetchedTier }));
//         setProfileData(data);
//         setProfile(data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching profile:", error);
//         setIsError(true);
//         setIsLoading(false);
//       }
//     };

//     fetchProfile();
//   }, [url, setUser, setProfile]);

//   return {
//     profileData,
//     isLoading,
//     isError,
//     tier,
//   };
// };

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// import BASE_URL from './index.js';
// import useProfile from '../hooks/useProfile.js';
// import useUser from '../hooks/useUser.jsx';

// const fetchProfileData = async (url, authToken) => {
// 	const headers = {
// 		Authorization: `Bearer ${authToken}`,
// 	};

// 	try {
// 		const response = await axios.get(url, { headers });
// 		// console.log(response.data);
// 		// console.log(response.data.isQuestionAnswered);
// 		return response.data;
// 	} catch (error) {
// 		throw new Error('Failed to fetch profile data');
// 	}
// };

// export const useProfileData = () => {
// 	const { profile, setProfile } = useProfile();
// 	console.log(`profile context` + profile);
// 	const getAuthTokenFromLocalDb = () => {
// 		return localStorage.getItem('responseData');
// 	};

// 	const authTokenWithQuotes = getAuthTokenFromLocalDb();
// 	const authToken = authTokenWithQuotes.replace(/^"(.*)"$/, '$1');
// 	const url = BASE_URL + `/account/settings/profile`;

// 	const [profileData, setProfileData] = useState(null);
// 	const [isLoading, setIsLoading] = useState(true);
// 	const [isError, setIsError] = useState(false);
// 	const [tier, setTier] = useState();
// 	const { user, setUser } = useUser();

// 	useEffect(() => {
// 		if (!authToken) {
// 			setIsError(true);
// 			setIsLoading(false);
// 			return;
// 		}

// 		fetchProfileData(url, authToken)
// 			.then((data) => {
// 				console.log(data);
// 				const fetchedTier = data?.data?.tier;
// 				setTier(fetchedTier);
// 				setUser((prev) => ({ ...prev, tier: fetchedTier }));
// 				setProfileData(data);
// 				setProfile(data);
// 				setIsLoading(false);
// 			})
// 			.catch((error) => {
// 				setIsError(true);
// 				setIsLoading(false);
// 			});
// 	}, [url, authToken]);

// 	return {
// 		profileData,
// 		isLoading,
// 		isError,
// 	};
// };

// import { useState, useEffect } from "react";
// import axios from "axios";
// import Cookies from 'js-cookie';

// import BASE_URL from "./index.js";
// import useProfile from "../hooks/useProfile.js";
// import useUser from "../hooks/useUser.jsx";

// const fetchProfileData = async (url, authToken) => {
//   const headers = {
//     Authorization: `Bearer ${authToken}`,
//   };

//   try {
//     const response = await axios.get(url, { headers });
//     return response.data;
//   } catch (error) {
//     throw new Error("Failed to fetch profile data");
//   }
// };

// export const useProfileData = () => {
//   const { setProfile } = useProfile();
//   const { setUser } = useUser();

//   // const getAuthTokenFromLocalDb = () => {
//   // 	const authTokenWithQuotes = localStorage.getItem('responseData');
//   // 	return authTokenWithQuotes ? authTokenWithQuotes.replace(/^"(.*)"$/, '$1') : null;
//   // };

//   // const authToken = getAuthTokenFromLocalDb();
//   // console.log(authToken);

//   // Function to get auth token directly from the 'accessToken' cookie
//   const getAuthToken = () => {
//     // Get the token directly from the 'accessToken' cookie
//     const accessToken = Cookies.get("accessToken", { domain: ".helpai.co" });

//     // Log for debugging purposes
//     console.log("Access Token from cookie:", accessToken);

//     // Fallback: Try to get the token from localStorage if the cookie is not found
//     if (!accessToken) {
//       const authTokenWithQuotes = localStorage.getItem("responseData");
//       return authTokenWithQuotes
//         ? authTokenWithQuotes.replace(/^"(.*)"$/, "$1")
//         : null;
//     }

//     return accessToken;
//   };

//   // Get auth token using the function defined above
//   const authToken = getAuthToken();
//   console.log("Auth Token:", authToken);

//   const url = BASE_URL + `/account/settings/profile`;

//   const [profileData, setProfileData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isError, setIsError] = useState(false);
//   const [setTier] = useState();

//   useEffect(() => {
//     if (!authToken) {
//       setIsError(true);
//       setIsLoading(false);
//       return;
//     }

//     fetchProfileData(url, authToken)
//       .then((data) => {
//         const fetchedTier = data.data.tier;
//         setTier(fetchedTier);
//         setUser((prev) => ({ ...prev, tier: fetchedTier }));
//         setProfileData(data);
//         setProfile(data);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         setIsError(true);
//         setIsLoading(false);
//       });
//   }, [url, authToken, setUser, setProfile]);

//   return {
//     profileData,
//     isLoading,
//     isError,
//   };
// };
