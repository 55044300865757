import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { PaystackButton } from 'react-paystack';

import hamburger from '../../../assets/images/hamburger.svg';
import bell from '../../../assets/images/notification-mobile.svg';
import sidehoge from '../../../assets/images/mobile-sidebar-logo.svg';
import dashneutral from '../../../assets/images/dashneutral.svg';
import messageneutral from '../../../assets/images/messagetext1.svg';
import likesneutral from '../../../assets/images/heart.svg';
import settingneutral from '../../../assets/images/settings.svg';
import dashpurple from '../../../assets/images/dashpurple.svg';
import messagepurple from '../../../assets/images/messagepurple.svg';
import likespurple from '../../../assets/images/likespurple.svg';
import settingspurple from '../../../assets/images/settingspurple.svg';
import '../../../assets/css/SideBar.scss';
import divider from '../../../assets/images/divider.svg';
import upgradepremium from '../../../assets/images/upgradepremium.svg';
import { useAuth } from '../../../hooks/useAuthentication';
import out from '../../../assets/images/tabler_logout.svg';
import BASE_URL from '../../../services/index.js';
import { useProfileData } from '../../../services/getProfileService';
import useUser from '../../../hooks/useUser';

const MobileHeader = () => {
	const { logout } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const [profile, setProfile] = useState({
		profilepic: null,
	});
	const [isDataFetched, setIsDataFetched] = useState(false);
	const { profileData } = useProfileData();
	const { user } = useUser();
	const publicKey = 'pk_live_7667f083a3fca566fb1a7e32c79f2eb9bb8b17d3';
	const plan = 'PLN_vispnpi0vpiorni';
	const amount = 1000000;
	const [name, setName] = useState(user.name);
	const [phone, setPhone] = useState(user.phone);
	const [email, setEmail] = useState(user.useremail);

	const drawerRef = useRef(null);

	const toggleDrawer = (event) => {
		event.stopPropagation();
		setIsOpen(!isOpen);
	};

	const closeDrawer = (event) => {
		if (!drawerRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('click', closeDrawer);
		}

		return () => {
			document.removeEventListener('click', closeDrawer);
		};
	}, [isOpen]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(BASE_URL + '/account/settings/profile', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				});
				const data = response.data.data;
				//console.log(data);

				setProfile({
					profilepic: data.profileImage,
				});
				setIsDataFetched(true);
			} catch (error) {
				console.error('Error fetching profile data:', error);
				setIsDataFetched(true);
			}
		};

		fetchData();
	}, []);

	// const handleMakePayment = () => {
	// 	axios
	// 		.post(
	// 			BASE_URL + `/transaction/make-payment`,
	// 			{},
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${user.token}`,
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			// console.log('Successful:', response.data);
	// 			if (response.data?.data?.checkout_url) {
	// 				window.open(response.data?.data?.checkout_url, '_blank');
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error making payment:', error);
	// 		});
	// };

	const handlePaystackSuccessAction = (reference) => {
		window.location.reload();
		console.log(reference);
	};

	// you can call this function anything
	const handlePaystackCloseAction = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed');
	};

	const componentProps = {
		reference: new Date().getTime().toString(),
		email,
		amount,
		metadata: {
			name,
			phone,
		},
		channels: ['card', 'qr', 'ussd', 'mobile_money', 'eft', 'bank_transfer', 'payattitude'],
		publicKey,
		plan,
		text: 'Upgrade',
		onSuccess: (reference) => handlePaystackSuccessAction(reference),
		onClose: handlePaystackCloseAction,
	};

	return (
		<>
			<div className='flex justify-between mb-7'>
				<div>
					<img
						src={hamburger}
						alt=''
						onClick={toggleDrawer}
						className='cursor-pointer'
					/>
				</div>
				<div className='flex items-center gap-6'>
					<div>
						<img
							src={bell}
							alt=''
						/>
					</div>
					<div>
						{isDataFetched ? (
							<img
								className='w-[40px] h-[40px] rounded-full object-cover'
								src={profile.profilepic}
								alt=''
							/>
						) : (
							<Skeleton
								circle={true}
								width={40}
								height={40}
							/>
						)}
					</div>
				</div>
			</div>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						ref={drawerRef}
						className='fixed inset-y-0 left-0 flex flex-col items-center w-[189px] bg-white shadow-lg transform translate-x-0 transition-transform duration-300 ease-in-out py-14 z-50'
						initial={{ opacity: 0, x: -300 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: -300 }}
						transition={{ duration: 0.3 }}>
						<div className='flex flex-col justify-between items-center'>
							<div className='mb-7'>
								<img
									src={sidehoge}
									alt='HelpAI logo'
								/>
							</div>

							<div className=''>
								<div className='flex gap-5 p-3 cursor-pointer mb-4'>
									<div>
										<img
											className=''
											src={location.pathname === '/dashboard' ? dashpurple : dashneutral}
											alt=''
										/>
									</div>
									<div>
										<Link
											to='/dashboard'
											className={`${location.pathname === '/dashboard' ? 'text-[#5655AA]' : 'text-gray'}`}>
											Dashboard
										</Link>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=''>
								<div className='flex gap-5 p-3 cursor-pointer mb-4 -ml-2'>
									<div>
										<img
											className=''
											src={location.pathname === '/messages' ? messagepurple : messageneutral}
											alt=''
										/>
									</div>
									<div>
										<Link
											to='/messages'
											className={`${location.pathname === '/messages' ? 'text-[#5655AA]' : 'text-gray'}`}>
											Messages
										</Link>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=''>
								<div className='flex gap-3 p-3 cursor-pointer mb-4 -ml-3'>
									<div>
										<img
											className=''
											src={location.pathname === '/likes' ? likespurple : likesneutral}
											alt=''
										/>
									</div>
									<div>
										<Link
											to='/likes'
											className={`${location.pathname === '/likes' ? 'text-[#5655AA]' : 'text-gray'}`}>
											Prospects
										</Link>
									</div>
								</div>
							</div>
							{/*  */}
							<div className=''>
								<div className='flex gap-5 p-3 cursor-pointer mb-4 -ml-4'>
									<div>
										<img
											className=''
											src={location.pathname === '/settings' ? settingspurple : settingneutral}
											alt=''
										/>
									</div>
									<div>
										<Link
											to='/settings'
											className={`${location.pathname === '/settings' ? 'text-[#5655AA]' : 'text-gray'}`}>
											Settings
										</Link>
									</div>
								</div>
							</div>

							<div className='mt-3'>
								<img
									src={divider}
									alt=''
								/>
							</div>

							{user ? (
								<div
									onClick={logout}
									className='flex justify-between mt-12 items-center gap-3 mb-12'>
									<div className='cursor-pointer'>
										<img
											src={out}
											alt=''
										/>
									</div>
									<div>
										<p className='capitalize changa-bold text-md text-[#EA4335]'>log out</p>
									</div>
								</div>
							) : (
								<div className='flex justify-between items-center mb-16 gap-3'>
									<div>
										<p className='capitalize changa-bold text-lg text-black'>invalid user</p>
									</div>
									<Link to='/'>
										<div className='cursor-pointer'>
											<img
												src={out}
												alt=''
											/>
										</div>
									</Link>
								</div>
							)}

							{user?.tier === 'Free' && (
								<div className='bg-[#E6E6FF] px-5 py-7 mt-12 2xl:mt-12 rounded-lg'>
									<>
										<div className='mb-3 upgrade-button'>
											<h1 className='text-[#5655AA] p-1 text-center text-2xl'>
												Upgrade to
												<br /> Premium
											</h1>
										</div>
										<div className='flex justify-center items-center'>
											{/* <button
												onClick={handleMakePayment}
												className='bg-[#454488] rounded-lg px-7 py-2'>
												<div className='upgrade-btn-text'> Upgrade </div>
											</button> */}
											<PaystackButton
												className='bg-[#414080] px-9 py-2.5 text-white changa rounded-lg cursor-pointer hover:bg-[#5958ae] duration-500 transition-all'
												{...componentProps}
											/>
										</div>
									</>
								</div>
							)}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default MobileHeader;
