import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { PaystackButton } from 'react-paystack';

import dashlogo from '../../../assets/images/dashboardlogo.svg';
import dashneutral from '../../../assets/images/dashneutral.svg';
import messageneutral from '../../../assets/images/messagetext1.svg';
import likesneutral from '../../../assets/images/heart.svg';
import settingneutral from '../../../assets/images/settings.svg';
import '../../../assets/css/SideBar.scss';
import upgradepremium from '../../../assets/images/upgradepremium.svg';
import divider from '../../../assets/images/divider.svg';
import dashpurple from '../../../assets/images/dashpurple.svg';
import messagepurple from '../../../assets/images/messagepurple.svg';
import likespurple from '../../../assets/images/likespurple.svg';
import settingspurple from '../../../assets/images/settingspurple.svg';
import { useAuth } from '../../../hooks/useAuthentication';
import out from '../../../assets/images/tabler_logout.svg';
import BASE_URL from '../../../services';
import useUser from '../../../hooks/useUser';

const Sidebar = ({ openReminderModal }) => {
	const location = useLocation();
	const { logout } = useAuth();
	const { user } = useUser();
	const publicKey = 'pk_live_7667f083a3fca566fb1a7e32c79f2eb9bb8b17d3';
	const plan = 'PLN_vispnpi0vpiorni';
	const amount = 1000000;
	const [name, setName] = useState(user.name);
	const [phone, setPhone] = useState(user.phone);
	const [email, setEmail] = useState(user.useremail);
	// console.log(user);

	const handlePaystackSuccessAction = (reference) => {
		window.location.reload();
		console.log(reference);
	};

	// you can call this function anything
	const handlePaystackCloseAction = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log('closed');
	};

	const componentProps = {
		reference: new Date().getTime().toString(),
		email,
		amount,
		metadata: {
			name,
			phone,
		},
		channels: ['card', 'qr', 'ussd', 'mobile_money', 'eft', 'bank_transfer', 'payattitude'],
		publicKey,
		plan,
		text: 'Upgrade',
		onSuccess: (reference) => handlePaystackSuccessAction(reference),
		onClose: handlePaystackCloseAction,
	};

	// const handleMakePayment = () => {
	// 	axios
	// 		.post(
	// 			BASE_URL + `/transaction/make-payment`,
	// 			{},
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${user.token}`,
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			// console.log('Successful:', response.data);
	// 			if (response.data?.data?.checkout_url) {
	// 				window.open(response.data?.data?.checkout_url, '_blank');
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error making payment:', error);
	// 		});
	// };

	return (
		<div className='w-1/5 bg-white h-screen gap-5 border border-r border-[#F5F5F5]'>
			<div className='flex flex-col items-center py-8'>
				<div className='mb-14'>
					<img
						src={dashlogo}
						alt='HelpAI logo'
						className='dashboard-header'
					/>
				</div>

				<div className=''>
					<div className='flex gap-5 p-3 cursor-pointer mb-4'>
						<div>
							<img
								src={location.pathname === '/dashboard' ? dashpurple : dashneutral}
								alt=''
							/>
						</div>
						<div>
							<Link
								to='/dashboard'
								className={`${location.pathname === '/dashboard' ? 'text-[#5655AA]' : 'text-gray'}`}>
								Dashboard
							</Link>
						</div>
					</div>
					<div className='flex gap-5 p-3 cursor-pointer mb-4'>
						<div>
							<img
								src={location.pathname === '/messages' ? messagepurple : messageneutral}
								alt=''
							/>
						</div>
						<div>
							<Link
								to='/messages'
								className={`${location.pathname === '/messages' ? 'text-[#5655AA]' : 'text-gray'}`}>
								Messages
							</Link>
						</div>
					</div>
					<div className='flex gap-5 p-3 cursor-pointer mb-4'>
						<div>
							<img
								src={location.pathname === '/likes' ? likespurple : likesneutral}
								alt=''
							/>
						</div>
						<div>
							<Link
								to='/likes'
								className={`${location.pathname === '/likes' ? 'text-[#5655AA]' : 'text-gray'}`}>
								Prospects
							</Link>
						</div>
					</div>
					<div className='flex gap-5 p-3 cursor-pointer mb-4'>
						<div>
							<img
								src={location.pathname === '/settings' ? settingspurple : settingneutral}
								alt=''
							/>
						</div>
						<div>
							<Link
								to='/settings'
								className={`${location.pathname === '/settings' ? 'text-[#5655AA]' : 'text-gray'}`}>
								Settings
							</Link>
						</div>
					</div>
				</div>

				<div>
					<img
						src={divider}
						alt='slice'
					/>
				</div>

				{user ? (
					<div
						onClick={logout}
						className='flex justify-between items-center gap-3 cursor-pointer mt-10'>
						<div className=''>
							<img
								src={out}
								alt=''
							/>
						</div>
						<div>
							<p className='capitalize changa-bold text-lg text-[#EA4335]'>log out</p>
						</div>
					</div>
				) : (
					<div className='flex justify-between items-center mb-16 gap-3 mt-10'>
						<div>
							<p className='capitalize changa-bold text-lg text-black'>invalid user</p>
						</div>
						<Link to='/'>
							<div className='cursor-pointer'>
								<img
									src={out}
									alt=''
								/>
							</div>
						</Link>
					</div>
				)}

				{user?.tier === 'Free' && (
					<div className='bg-[#E6E6FF] px-5 py-7 mt-12 2xl:mt-12 rounded-lg'>
						<>
							<div className='mb-3'>
								<h1 className='text-[#5655AA] p-1 text-center text-2xl'>
									Upgrade to
									<br /> Premium
								</h1>
							</div>
							<div className='flex justify-center items-center'>
								{/* <button
									onClick={handleMakePayment}
									className='bg-[#454488] rounded-lg px-7 py-2'>
									<div className='upgrade-btn-text'> Upgrade </div>
								</button> */}
								<PaystackButton
									className='bg-[#414080] px-9 py-2.5 text-white changa rounded-lg cursor-pointer hover:bg-[#5958ae] duration-500 transition-all upgrade-button'
									{...componentProps}
								/>
							</div>
						</>
					</div>
				)}
			</div>
		</div>
	);
};

export default Sidebar;
