import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

import Layout from "../layout.jsx";
import search from "../../../assets/images/searchnormal1.svg";
import personal from "../../../assets/images/personal.svg";
import integration from "../../../assets/images/integration.svg";
import support from "../../../assets/images/support.svg";
import refer from "../../../assets/images/refer.svg";
import notification from "../../../assets/images/notification.svg";
import MobileHeader from "../components/MobileHeader.jsx";

const Settings = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    const fetchCookie = async () => {
      try {
        // Get the cookie value directly using js-cookie
        const cookieValue = Cookies.get("hai_auth", { domain: ".helpai.co" });

        // console.log("Cookie Value:", cookieValue);

        if (cookieValue) {
          // Parse the JSON string to get individual values
          const cookieObject = JSON.parse(cookieValue);
          const { email, idToken: accessToken } = cookieObject;

          // console.log("Email:", email);
          // console.log("Access Token:", accessToken);

          // Set cookies using js-cookie
          Cookies.set("userEmail", email);
          Cookies.set("accessToken", accessToken);
          localStorage.setItem("userEmail", email);
          localStorage.setItem("accessToken", accessToken);
        }
      } catch (error) {
        console.error("Error parsing cookie value:", error);
      }
    };

    fetchCookie();
  }, []);

  const goToRefer = () => {
    navigate("/referral");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const supportCardsData = [
    {
      back: "bg-white cursor-pointer",
      icon: personal,
      heading: "Your Profile",
      subtext: "View and update your personal and professional information",
      toClick: goToProfile,
    },
    {
      back: "bg-[#F0F0F0] cursor-not-allowed",
      icon: notification,
      heading: "Notificaions",
      subtext: "Manage your notifications to stay informed about updates",
    },
    {
      back: "bg-[#F0F0F0] cursor-not-allowed",
      icon: support,
      heading: "Support Center",
      subtext: "Need help? Access FAQs, guides or get in touch with our team",
    },
    {
      back: "bg-[#F0F0F0] cursor-not-allowed",
      icon: integration,
      heading: "Integrations",
      subtext: "Manage calendar syncing and integration preferences",
    },
    {
      back: "bg-white cursor-pointer",
      icon: refer,
      heading: "Refer a Friend",
      subtext: "Unlock rewards by inviting your friends and enemies",
      toClick: goToRefer,
    },
  ];

  return (
    <div>
      {isTabletOrMobile ? (
        <div className="bg-[#FAFBFD] px-2 py-14 h-auto">
          <MobileHeader />
          <>
            <div className="mb-5">
              <h1 className="changa-bold text-xl capitalize mb-[2px] text-[#454488]">
                Settings
              </h1>
            </div>

            <div className="md:grid md:grid-cols-3 md:grid-row-2 gap-2">
              {supportCardsData.map((cardData, index) => (
                <SupportCard key={index} {...cardData} />
              ))}
            </div>
          </>
        </div>
      ) : (
        <Layout>
          <div className="px-9 py-5 w-full">
            <div className="mb-16">
              <h1 className="changa-bold text-xl capitalize mb-[2px] text-[#454488]">
                Settings
              </h1>
            </div>
            <div className="flex justify-center items-center">
              <div className="md:grid md:grid-cols-3 md:grid-row-2 gap-2">
                {supportCardsData.map((cardData, index) => (
                  <SupportCard key={index} {...cardData} />
                ))}
              </div>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default Settings;

const SupportCard = ({ icon, heading, subtext, back, toClick }) => {
  return (
    <div
      onClick={toClick}
      className={`${back} flex justify-center items-center shadow px-5 py-5 rounded-md mb-5 md:w-[263px]`}
    >
      <div>
        <div className="flex justify-center items-center">
          <img className="mb-2" src={icon} alt="" />
        </div>
        <h3 className="text-center mb-1 text-[#373737] changa-semibold">
          {heading}
        </h3>
        <p className="text-center mb-1 text-[14px]">{subtext}</p>
      </div>
    </div>
  );
};
